import React from 'react';

function Header() {
  return (
    <header className="bg-[#3A3E4F] py-5 rounded-b-3xl shadow-box">
      <div className="container mx-auto">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-3 flex items-center justify-center lg:justify-start">
            <a href="/">
              <img
                src="/assets/images/logo-white.png"
                alt="BVL"
                className="mx-auto lg:mx-0 w-32"
              />
            </a>
          </div>

          <div className="col-span-12 lg:col-span-6 flex items-center justify-center mt-0 md:mt-auto">
            <h2 className="text-white text-center lg:text-2xl text-xl font-semibold">
              CHIẾN DỊCH <span className="align-sup mr-[1px]">&#10077;</span>
              NÂNG TẦM QUẢN LÝ
              <span className="align-sub ml-[1px]">&#10078;</span>
            </h2>
          </div>

          <div className="col-span-12 lg:col-span-3 hidden lg:block" />
        </div>
      </div>
    </header>
  );
}

export default Header;
