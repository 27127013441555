import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import axios from 'axios';

import Header from 'layouts/components/Header';
import CountDown from 'layouts/components/CountDown';
import TotalNumber from 'layouts/components/TotalNumber';
import BonusFund from 'layouts/components/boxes/BonusFund';
import ClubRevenueAverage from 'layouts/components/boxes/ClubRevenueAverage';
import ActivityRanking from 'layouts/components/boxes/ActivityRanking';
import ClubGroupDataBox from 'layouts/components/ClubGroupDataBox';

function Layout() {
  const [selectedTime, setSelectedTime] = useState(dayjs());
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get(
        `${window.API_ENDPOINT}/leader-promotion/get-statistics`,
      );

      setData(_.get(response, 'data.result', []));
    };

    fetchData().finally(() => setLoading(false));
  }, []);
  const clubData = _.get(data, 'data');

  return (
    <div className="h-full">
      <Header />
      <main className="container mx-auto py-4">
        <CountDown />
        <div className="lg:pt-12 pt-8 lg:pb-12 pb-2">
          <div className="grid lg:gap-4 gap-2 grid-cols-12 h-full">
            <div className="lg:col-span-4 col-span-12 order-2 lg:order-1 lg:px-0 px-2">
              <BonusFund data={clubData} />
              <div className="lg:mt-5 mt-2">
                <ClubRevenueAverage data={clubData} />
              </div>
            </div>
            <div className="lg:col-span-4 col-span-12 order-1 lg:order-2 lg:px-0 px-2">
              <TotalNumber value={_.get(data, 'total_money')} />
            </div>
            <div className="lg:col-span-4 col-span-12 order-3 lg:order-3 lg:px-0 px-2">
              <ActivityRanking
                onSelectTime={setSelectedTime}
                selectedTime={selectedTime}
                data={clubData}
                loading={loading}
              />
            </div>
          </div>
        </div>
        <div className="mt-2 mb-8">
          <ClubGroupDataBox
            monthData={_.get(data, 'month_data')}
            loading={loading}
            selectedTime={selectedTime}
          />
        </div>
      </main>
    </div>
  );
}

export default Layout;
