const COLOR_PRIMARY = "#0079c1";
const COLOR_SECONDARY = "#ffb800";

const COLOR_SUCCESS = "#52c41a";
const COLOR_WARNING = "#faad14";
const COLOR_DANGER = "#ff4d4f";

export {
  COLOR_SUCCESS,
  COLOR_WARNING,
  COLOR_DANGER,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
};
