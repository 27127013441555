import React, { useState } from 'react';
import { DatePicker, Progress } from 'antd';
import styled, { keyframes } from 'styled-components';
import { NumericFormat } from 'react-number-format';
import { endOfYear2024, startOfOctober2024 } from 'constants';
import { COLOR_PRIMARY } from 'styles/constants';
import TableWithoutBorderAndBackground from 'layouts/elements/TableWithoutBorderAndBackground';
import _ from 'lodash';
import { clubs } from 'constants/clubs';
import dayjs from 'dayjs';

const rotateIn = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
`;

const rotateOut = keyframes`
    0% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(0deg);
    }
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 0 0.75rem;

  .ant-picker-input {
    padding: 0.5rem 0;
    font-size: 1.125rem;

    &:before {
      content: '▼';
      transition: transform 0.3s ease-in-out;
      animation: ${({ isOpen }) => (isOpen ? rotateIn : rotateOut)} 0.3s
        forwards;
      transform-origin: center;
      margin-left: 0.5rem;
      position: absolute;
    }
    input {
      padding: 0 3rem;
      text-align: center;
      cursor: default;
      font-size: 1.125rem; /* 18px */
      line-height: 1.75rem; /* 28px */
      font-weight: 600;
    }
  }
`;

function ActivityRanking({ onSelectTime, selectedTime, data, loading }) {
  const [isOpen, setIsOpen] = useState(false);

  const result = _.take(
    _.orderBy(
      _.flatMap(data, (value) => value.groups),
      ['standard_activity', 'competition_ip'],
      ['desc', 'desc'],
    ),
    10,
  );
  const highestActivity = result[0];

  const getMaxDate = () => {
    const date = dayjs();
    if (date.isAfter(endOfYear2024)) {
      return endOfYear2024;
    }
    return date;
  };

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="py-3 lg:px-6 px-3 border rounded-lg animate-fadeInUp mb-5 lg:bg-transparent bg-slate-800">
        <div className="text-center">
          <span className="text-white font-semibold text-lg">
            BẢNG XẾP HẠNG LƯỢT HOẠT ĐỘNG
          </span>
        </div>
        <div className="mt-2">
          <div className="rounded-lg">
            <TableWithoutBorderAndBackground
              dataSource={result}
              columns={[
                {
                  dataIndex: 'group_name',
                  render: (value, record) => (
                    <img
                      className="h-[28px]"
                      src={_.get(clubs, [record?.company_code, 'avatar'])}
                      alt={value}
                    />
                  ),
                  width: '10%',
                },
                {
                  dataIndex: 'standard_activity',
                  render: (value, record) => {
                    const percent =
                      (value / highestActivity.standard_activity) * 100;

                    return (
                      <div>
                        <div>
                          <span className="text-white">
                            {record?.group_name}
                          </span>
                        </div>
                        <Progress
                          percent={percent}
                          showInfo={false}
                          size={{ height: 12 }}
                          strokeColor={COLOR_PRIMARY}
                        />
                      </div>
                    );
                  },
                  width: '40%',
                },
                {
                  align: 'end',
                  width: '20%',
                  render: (value, record) => (
                    <div>
                      <div>
                        <NumericFormat
                          value={record.standard_activity}
                          allowLeadingZeros
                          thousandSeparator=","
                          displayType="text"
                          className="text-white"
                          suffix=" lượt"
                        />
                      </div>
                      <div>
                        <NumericFormat
                          value={record.competition_ip}
                          allowLeadingZeros
                          thousandSeparator=","
                          displayType="text"
                          className="text-yellow-300"
                          prefix="IP "
                          suffix=" trđ"
                        />
                      </div>
                    </div>
                  ),
                },
              ]}
              loading={loading}
              rowKey="group_code"
            />
          </div>
        </div>
      </div>

      <StyledDatePicker
        onChange={onSelectTime}
        picker="month"
        format="[THÁNG] M"
        value={selectedTime}
        suffixIcon={null}
        allowClear={false}
        rootClassName={`w-fit mx-auto rounded-full border border-white font-semibold text-center hover:text-black focus:text-black hover:bg-white hover:border-white  ${
          isOpen ? 'text-black' : 'text-white'
        }  lg:bg-transparent bg-slate-800`}
        popupClassName="border-[unset] border-0"
        maxDate={getMaxDate()}
        minDate={startOfOctober2024}
        onOpenChange={setIsOpen}
        isOpen={isOpen}
        inputReadOnly
      />
    </div>
  );
}

export default ActivityRanking;
