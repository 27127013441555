export const CLUB_SON_TAY = 'CLUB_SON_TAY';
export const CLUB_NAM_DINH = 'CLUB_NAM_DINH';
export const CLUB_VINH_PHUC = 'CLUB_VINH_PHUC';

export const clubNames = {
  [CLUB_SON_TAY]: 'CLB SƠN TÂY',
  [CLUB_NAM_DINH]: 'CLB NAM ĐỊNH',
  [CLUB_VINH_PHUC]: 'CLB VĨNH PHÚC',
};

export const clubs = {
  [CLUB_NAM_DINH]: {
    name: 'CLB NAM ĐỊNH',
    short_name: 'NAM ĐỊNH',
    avatar: '/assets/images/avatar/clb-nam-dinh.png',
  },
  [CLUB_SON_TAY]: {
    name: 'CLB SƠN TÂY',
    short_name: 'SƠN TÂY',
    avatar: '/assets/images/avatar/clb-son-tay.png',
  },
  [CLUB_VINH_PHUC]: {
    name: 'CLB VĨNH PHÚC',
    short_name: 'VĨNH PHÚC',
    avatar: '/assets/images/avatar/clb-vinh-phuc.png',
  },
};
