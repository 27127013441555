import React from 'react';
import ReactCountdown from 'react-countdown';
import { endOfYear2024 } from 'constants';

function CountDown() {
  const renderCountDown = ({ hours, minutes, seconds, days }) => {
    return (
      <div className="flex justify-start">
        <div className="py-1 px-3 flex flex-col align-middle justify-center text-center mx-1 border border-slate-50 rounded bg-black">
          <span className="text-xl">{days}</span>
          <span className="text-xs font-normal">Ngày</span>
        </div>
        <div className="py-1 px-3 flex flex-col align-middle justify-center text-center mx-1 border border-slate-50 rounded bg-black">
          <span className="text-xl">{hours}</span>
          <span className="text-xs font-normal">Giờ</span>
        </div>
        <div className="py-1 px-3 flex flex-col align-middle justify-center text-center mx-1 border border-slate-50 rounded bg-black">
          <span className="text-xl">{minutes}</span>
          <span className="text-xs font-normal">Phút</span>
        </div>
        <div className="py-1 px-3 flex flex-col align-middle justify-center text-center mx-1 border border-slate-50 rounded bg-black">
          <span className="text-xl">{seconds}</span>
          <span className="text-xs font-normal">Giây</span>
        </div>
      </div>
    );
  };

  return (
    <div className="col-span-12 lg:col-span-6 flex flex-col lg:flex-row justify-start items-center mt-0 md:mt-auto">
      <span className="text-white text-xl font-semibold">
        Thời gian chiến dịch
      </span>
      <div className="text-white font-semibold px-2">
        <ReactCountdown
          date={endOfYear2024.toDate()}
          renderer={renderCountDown}
        />
      </div>
    </div>
  );
}

export default CountDown;
