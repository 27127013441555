import React from 'react';
import CountUp from 'react-countup';

function TotalNumber({ value = 0 }) {
  return (
    <div className="lg:mt-[30vh] border border-white rounded-xl lg:px-8 lg:py-6 px-5 py-4 text-white text-center bg-slate-800 animate-pulse">
      <CountUp
        end={value}
        separator=","
        prefix="$"
        className="text-5xl font-bold"
        delay={0.5}
      />
      <div className="mt-2">
        <span className="font-medium lg:text-xl text-lg">QUỸ PHÁT LỘC</span>
      </div>
    </div>
  );
}

export default TotalNumber;
