import React from 'react';
import _ from 'lodash';
import { NumericFormat } from 'react-number-format';
import { clubs } from 'constants/clubs';

function BonusFund({ data = {} }) {
  return (
    <div className="py-3 px-6 border rounded-lg animate-fadeInUp lg:bg-transparent bg-slate-800">
      <div className="text-center">
        <span className="text-white font-semibold text-lg">QUỸ THƯỞNG</span>
      </div>
      <div className="mt-3">
        {_.entries(data).map(([key, clubData]) => {
          const clubName = _.get(clubData, 'company_name');
          return (
            <div className="flex justify-between items-center my-2" key={key}>
              <div>
                <div className="flex items-center">
                  <img
                    width={32}
                    src={_.get(clubs, [key, 'avatar'])}
                    alt={clubName}
                  />
                  <span className="text-white ml-2">{clubName}</span>
                </div>
              </div>
              <div>
                <div className="text-center">
                  <NumericFormat
                    value={_.get(clubData, 'total_money', 0)}
                    allowLeadingZeros
                    thousandSeparator=","
                    displayType="text"
                    className="text-yellow-300"
                    prefix="+$"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BonusFund;
