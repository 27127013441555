import styled from 'styled-components';
import { Table as AntTable } from 'antd';
import React from 'react';

const StyledTable = styled(AntTable)`
  &&& {
    .ant-table,
    td,
    th {
      background-color: transparent !important;
      border-color: transparent !important;
      color: white;
    }
    th {
      &:before {
        width: 0 !important;
      }
    }
    td:not([class*='px-']) {
      padding: 0.25rem 0 !important;
      &:nth-child(2) {
        padding: 0 0.5rem !important;
      }
    }
  }
`;

const TableWithoutBorderAndBackground = (props) => {
  const components = {
    body: {
      cell: ({ children, ...restProps }) => (
        <td className="border-none bg-transparent" {...restProps}>
          {children}
        </td>
      ),
    },
  };

  return (
    <StyledTable
      pagination={false}
      size="small"
      showHeader={false}
      components={components}
      {...props}
      className={`border-none bg-transparent ${props.className || ''}`}
      rowClassName={`border-none bg-transparent ${props.rowClassName || ''}`}
    />
  );
};

export default TableWithoutBorderAndBackground;
