import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { ConfigProvider } from 'antd';
import viVN from 'antd/lib/locale/vi_VN';
import ThemeProvider from './styles/theme-provider';
import Layout from './layouts';

// =============== dayjs ===============
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(quarterOfYear);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');
dayjs.locale('vi');
// =============== /dayjs ===============

function App() {
  return (
    <>
      <ConfigProvider
        theme={{ token: { colorPrimary: '#0079c1' } }}
        locale={viVN}
      >
        <ThemeProvider>
          <Layout />
        </ThemeProvider>
      </ConfigProvider>
      <Analytics mode="production" />
      <SpeedInsights />
    </>
  );
}

export default App;
