import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';
import {
  COLOR_DANGER,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_SUCCESS,
} from 'styles/constants';
import _ from 'lodash';
import { clubs } from 'constants/clubs';

const CustomTooltip = ({ payload, label }) => {
  if (payload && payload.length) {
    return (
      <div className="custom-tooltip bg-white p-2 rounded">
        <p>{`${label} BQ Q3: ${payload[0].value}tr`}</p>
        <p>Tỉ trọng Q4 sv Q3: {payload[0].payload.growth}%</p>
      </div>
    );
  }
  return null;
};

const RevenueChart = ({ data }) => {
  const renderCustomLabel = (
    { x, y, width, value },
    valueKey,
    showGrowthPercent = true,
    showAvatar = true,
  ) => {
    const item = _.get(data, value);
    const image = clubs[value]?.avatar;
    const ip = _.round(_.get(item, valueKey, 0), 0).toLocaleString('vi-VN', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    const growth = item?.growth_percent;

    const textPositionY = !showGrowthPercent ? y - 10 : y - 35;
    const textPositionX = x + width / 2 - (showAvatar ? 14 : 18);

    return (
      <>
        {showAvatar ? (
          <image
            href={image}
            x={textPositionX} // Adjust image position horizontally
            y={y - 90}
            width={36}
            height={36}
          />
        ) : null}

        {/* Render ip and growth to the right of the avatar */}
        <text
          x={textPositionX}
          y={textPositionY}
          fill="#fff"
          fontSize="14"
          fontWeight="bold"
        >
          {ip} tr
        </text>
        {showGrowthPercent ? (
          <text
            x={textPositionX}
            y={y - 16}
            fill={growth < 0 ? COLOR_DANGER : COLOR_SUCCESS}
            fontSize="12"
          >
            {growth}%
          </text>
        ) : null}
      </>
    );
  };

  const getChartData = () => {
    const arr = [];

    _.entries(data).map(([key, values]) => {
      const name = _.get(values, 'company_name');
      const ip = _.get(values, 'total_ip', 0);
      const growthPercent = _.get(values, 'growth_percent', 0);
      const averageIp = _.get(values, 'average_ip', 0);
      const averageIpPreviousQuarter = _.get(
        values,
        'average_ip_previous_quarter',
        0,
      );

      arr.push({
        name,
        ip,
        growth: growthPercent,
        image: _.get(clubs, [key, 'avatar']),
        key,
        averageIp,
        averageIpPreviousQuarter,
      });
    });

    return _.orderBy(arr, 'growth', 'desc');
  };

  const renderLegend = (props) => {
    const { payload } = props;
    const keys = ['Quý 3', 'Quý 4'];

    return (
      <div className="-mt-16 flex items-center justify-center">
        {payload.map((entry, index) => (
          <div key={`item-${index}`} className="flex items-center mx-3">
            <div
              className={`w-[14px] h-[14px] rounded-full mr-1`}
              style={{
                backgroundColor: entry.color,
              }}
            />
            <span
              style={{
                color: entry.color,
              }}
            >
              {keys[index]}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={getChartData()}
        margin={{ top: 70, right: 0, left: 0, bottom: 40 }}
      >
        <XAxis dataKey="name" tick={{ fill: 'white' }} />

        <Tooltip content={<CustomTooltip />} />
        <Legend
          align="left"
          verticalAlign="top"
          height={36}
          content={renderLegend}
        />

        <Bar
          dataKey="averageIpPreviousQuarter"
          fill={COLOR_SECONDARY}
          barSize={40}
          radius={[10, 10, 0, 0]}
        >
          <LabelList
            dataKey="key"
            content={({ x, y, width, value }) =>
              renderCustomLabel(
                { x, y, width, value },
                'average_ip_previous_quarter',
                false,
                false,
              )
            }
          />
        </Bar>

        <Bar
          dataKey="averageIp"
          fill={COLOR_PRIMARY}
          barSize={40}
          radius={[10, 10, 0, 0]}
        >
          <LabelList
            dataKey="key"
            content={({ x, y, width, value }) =>
              renderCustomLabel({ x, y, width, value }, 'average_ip')
            }
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default RevenueChart;
