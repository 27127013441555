import React, { useEffect, useState } from 'react';
import { clubs } from 'constants/clubs';
import TableWithoutBorderAndBackground from 'layouts/elements/TableWithoutBorderAndBackground';
import { NumericFormat } from 'react-number-format';
import _ from 'lodash';
import axios from 'axios';

function ClubGroupDataBox({ selectedTime }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get(
        `${window.API_ENDPOINT}/leader-promotion/get-group-statistics-by-month`,
        { params: { month_ts: selectedTime.unix() } },
      );

      setData(_.get(response, 'data.result', []));
    };

    fetchData().finally(() => setLoading(false));
  }, [selectedTime]);

  return (
    <div className="grid lg:gap-4 gap-2 grid-cols-12">
      {_.entries(data).map(([key, values]) => {
        const club = _.get(clubs, key);
        const clubName = _.get(values, [0, 'company_name']);

        return (
          <div className="lg:col-span-4 col-span-12 lg:px-0 px-2" key={key}>
            <div className="h-full rounded-3xl p-4 bg-slate-900 shadow-box">
              <div className="flex items-center justify-center">
                <img className="h-[48px]" src={club.avatar} alt={clubName} />
                <span className="text-white font-semibold text-xl ml-3">
                  {clubName}
                </span>
              </div>
              <div className="mt-2">
                <TableWithoutBorderAndBackground
                  loading={loading}
                  dataSource={_.orderBy(
                    values,
                    ['standard_activity', 'competition_ip'],
                    ['desc', 'desc'],
                  )}
                  rowKey="group_code"
                  columns={[
                    {
                      title: 'AD',
                      dataIndex: 'ad_name',
                      align: 'center',
                    },
                    {
                      title: 'Nhóm',
                      dataIndex: 'group_name',
                      align: 'center',
                    },
                    {
                      title: 'Lượt chuẩn',
                      align: 'center',
                      render: (value, record) => (
                        <div>
                          <div>
                            <NumericFormat
                              value={record.standard_activity}
                              allowLeadingZeros
                              thousandSeparator=","
                              displayType="text"
                              className="text-white"
                            />
                          </div>
                          <div>
                            <NumericFormat
                              value={record.money}
                              allowLeadingZeros
                              thousandSeparator=","
                              displayType="text"
                              className="text-yellow-300"
                              prefix="+$"
                            />
                          </div>
                        </div>
                      ),
                      className: '!px-1',
                    },
                    {
                      title: 'IP',
                      dataIndex: 'competition_ip',
                      render: (value) => (
                        <NumericFormat
                          value={value}
                          allowLeadingZeros
                          thousandSeparator=","
                          displayType="text"
                          suffix=" trđ"
                        />
                      ),
                      className: '!px-1',
                    },
                  ]}
                  showHeader
                  className="text-white"
                  rowClassName="text-white"
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ClubGroupDataBox;
