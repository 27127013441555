import React from 'react';
import RevenueChart from 'layouts/components/boxes/RevenueChart';

function ClubRevenueAverage({ data }) {
  return (
    <div className="py-3 px-6 border rounded-lg animate-fadeInUp lg:bg-transparent bg-slate-800">
      <div className="text-center">
        <span className="text-white font-semibold text-lg uppercase">
          Doanh thu bình quân mỗi nhóm/Quý
        </span>
      </div>
      <div className="mt-2 h-[400px]">
        <RevenueChart data={data} />
        <div className="-mt-8">
          <p className="text-xs text-neutral-300 italic">
            * % tỉ trọng BQ doanh thu Quý 4 so với Quý 3
            <br />
            (% tăng trưởng sẽ update vào tháng 12/2024)
          </p>
        </div>
      </div>
    </div>
  );
}

export default ClubRevenueAverage;
